import React from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import "./delete.scss"

export default function Delete({ handleBackDelete }) {

    return (
        <>
            <div className='DeleteSection'>
                <div className='CloseBtn' onClick={() => handleBackDelete()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>

                <div className='DeleteHeader'>
                    <span className='DeleteTitle'>Delete Account</span>
                </div>

                <div className='BtnSection'>
                    <div className='DeleteBtn'>
                        <span className='DeleteBtnText'>I Want A Fresh Start</span>
                    </div>

                    <div className='DeleteBtn'>
                        <span className='DeleteBtnText'>I Don't Like lorem ipsum</span>
                    </div>

                    <div className='DeleteBtn'>
                        <span className='DeleteBtnText'>There are Issues With This Application</span>
                    </div>

                    <div className='DeleteBtn'>
                        <span className='DeleteBtnTextLast'>Other</span>
                    </div>
                </div>
            </div>
        </>
    )
}
