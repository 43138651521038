import React from 'react'
import { Route, Routes as Router } from 'react-router-dom';
import Homepage from '../pages/HomePage/Homepage';
import Unsplash from '../pages/splash/Unsplash'
import WelcomePage from '../pages/WelcomePage/WelcomePage';

export default function Routes() {
    return (
        <>

            <Router>

                <Route path='/' element={
                    <Unsplash />
                } />

                <Route path='/welcome' element={
                    <WelcomePage />
                } />

                <Route path='/home' element={
                    <Homepage />
                } />

            </Router>

        </>
    )
}
