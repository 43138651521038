import React, {useEffect, useState} from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import { Input } from 'antd'
import "./profilesetting.scss"
import axios from "axios";
import { useNavigate } from "react-router-dom"


export default function ProfileSetting({ handleBackSetting, handleSettingPassword }) {
    const navigate = useNavigate()

    const [userData , setUserData] = useState(false);
    const [firstName , setFirstName] = useState("");
    const [lastName , setLastName] = useState("");
    const [email , setEmail] = useState("");
    const [dob , setDob] = useState("");
    const [address , setAddress] = useState("");
    const [date , setDate] = useState("");
    const [month , setMonth] = useState("");
    const [year , setYear] = useState("");
    const [id , setID] = useState("");
    useEffect( () => {
        let getUserData = JSON.parse(localStorage.getItem('data'));
        if(getUserData != null) {
            setUserData(true);
            setDob(getUserData.dob)
            let dobObj = dob.split("-");
            let getDate = dobObj[2];
            let getMonth = dobObj[1];
            let getYear = dobObj[0];
            setDate(getDate);
            setMonth(getMonth);
            setYear(getYear);
            setFirstName(getUserData.first_name);
            setLastName(getUserData.last_name);
            setEmail(getUserData.email);
            setAddress(getUserData.address);
            setID(getUserData.id);
        }
        else{
            setUserData(false);

            setDate("");
            setMonth("");
            setYear("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setAddress("");
            setID("");
        }
    } , [userData])

    const updateUserData = async (e) => {
        e.preventDefault();

        if(userData == true){

            const formData = new FormData()

            formData.append('first_name', firstName)
            formData.append('last_name', lastName)
            formData.append('email', email)
            formData.append('id', id)

            formData.append('dob', ''+ year + '-' + month + '-' + date )
            formData.append('address', address)

            await axios.post(`http://gameble-server.codesage.ca/api/profileUpdate/${id}`, formData).then(({data}) => {
                alert(data.message);
                localStorage.setItem("data" ,  JSON.stringify(data.response))
            }).catch(({response}) => {
                if (response.status === 422) {
                } else {

                }
            })
        }
        else{
            navigate("/welcome");
        }
    }

    return (
        <>
            <div className='ProfileSetting'>
                <div className='ProfileHeader'>
                    <div className='CloseBtn' onClick={() => handleBackSetting()}>
                        <IoIosArrowRoundBack className="CloseBtnIcon" />
                    </div>
                    <span className='EditText'>Edit Profile</span>
                </div>
                <form onSubmit={updateUserData} >
                <div className='SettingForm'>
                    <div className="SettingBox">
                        <img className="EmailImg" src={require("../../../assets/images/user.png")} alt="" />
                        <div className='SettingInputBox'>
                            <Input className="SettingInput"  type="text" placeholder='First Name' value={firstName} onChange={(event)=>{
                                setFirstName(event.target.value)
                            }} />
                        </div>
                    </div>

                    <div className="SettingBox">
                        <img className="EmailImg" src={require("../../../assets/images/user.png")} alt="" />
                        <div className='SettingInputBox'>
                            <Input className="SettingInput" type="text" placeholder='Last Name' value={lastName} onChange={(event)=>{
                                setLastName(event.target.value)
                            }} />
                        </div>
                    </div>

                    <div className="SettingBox">
                        <img className="EmailImg" src={require("../../../assets/images/ic_email.png")} alt="" />
                        <div className='SettingInputBox'>
                            <Input className="SettingInput" type="text" placeholder='Email Name' value={email} onChange={(event)=>{
                                setEmail(event.target.value)
                            }} />
                        </div>
                    </div>

                    <div className='SettingDateSection'>
                        <div className="SettingBox">
                            <img className="EmailImg" src={require("../../../assets/images/calIcon.png")} alt="" />
                            <div className='SettingInputBox'>
                                <Input className="SettingInput" placeholder='DD' value={date} onChange={(event)=>{
                                    setDate(event.target.value)
                                }} />
                            </div>
                        </div>

                        <div className="SettingBox">
                            <img className="EmailImg" src={require("../../../assets/images/calIcon.png")} alt="" />
                            <div className='SettingInputBox'>
                                <Input className="SettingInput" placeholder='MM' value={month} onChange={(event)=>{
                                    setMonth(event.target.value)
                                }} />
                            </div>
                        </div>

                        <div className="SettingBox">
                            <img className="EmailImg" src={require("../../../assets/images/calIcon.png")} alt="" />
                            <div className='SettingInputBox'>
                                <Input className="SettingInput" placeholder='YYYY' value={year} onChange={(event)=>{
                                    setYear(event.target.value)
                                }} />
                            </div>
                        </div>
                    </div>

                    <div className="SettingBox">
                        <img className="EmailImg" src={require("../../../assets/images/Vector.png")} alt="" />
                        <div className='SettingInputBox'>
                            <Input className="SettingInput" placeholder='Address' value={address} onChange={(event)=>{
                                setAddress(event.target.value)
                            }} />
                        </div>
                    </div>
                </div>
                <div className='SettingChangePassword'>
                    <span className='ChangePasswordText' onClick={() => handleSettingPassword()}>Change Password?</span>
                </div>

                <div className='SettingBtnSection'>
                    <button className='SettingPasswordBtn' type="submit">Save</button>
                </div>
                </form>

            </div>
        </>
    )
}
