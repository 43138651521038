import "./login.scss"
import React, {useEffect, useState} from 'react'
import { Input } from "antd"
import axios from "axios";

export default function Login({ handleforgotPassword, handleSignUp }) {
    const [hideRegister, setHideRegister] = useState(true)
    // eslint-disable-next-line 
    const [body, setBody] = useState({})


    const handleInput = (e) => {
        body.email = e.target.value
        if (body?.email?.length > 0) {
            setHideRegister(false)
        } else {
            setHideRegister(true)
        }
    }
    const [email , setEmail] = useState("");
    const [password , setPassword] = useState("");
    const [name , setName] = useState("");
    const signIn = async (e) => {
        e.preventDefault();

        const formData = new FormData()


        formData.append('email', email)
        formData.append('password', password)

        await axios.post(`http://gameble-server.codesage.ca/api/auth/signin`, formData).then(({data}) => {
           alert(data.message);
           if(data.status == true){
                   localStorage.setItem('userName', data.response.first_name + " " + data.response.last_name);
                   localStorage.setItem('data', JSON.stringify(data.response));
           }
        }).catch(({response}) => {
            console.log("this is response"  , response);
        })
    }

    return (
        <>

            <div className="LoginFormBox">
                <div className="LoginHeader">
                    <span className="GetSomethingText">Let's Get Something</span>
                    <span className="AddressText">Please enter your address below to start using app.</span>
                </div>

                <div className="LoginForm">
                <form onSubmit={signIn}>
                    <div className="LoginBox">
                        <img className="LoginImg" src={require("../../../assets/images/ic_email.png")} alt="" />
                        <Input className="LoginInput" onChange={(event)=>{
                            setEmail(event.target.value)
                        }} type="text" placeholder="Email" />
                    </div>
                    <div className="LoginBox">
                        <img className="LoginImg" src={require("../../../assets/images/ic_lock.png")} alt="" />
                        <Input.Password className="LoginInput" onChange={(event)=>{
                            setPassword(event.target.value)
                        }} placeholder="Password" />
                    </div>

                    <div className="ForgotPasswordSection">
                        <span className="ForgotPasswordText" onClick={() => handleforgotPassword()}>Forgot Password?</span>
                    </div>

                    <div className="BtnSection">
                        <button className="SignInBtn" type="submit">Sign In</button>
                        {hideRegister && <button className="SignUpBtn" onClick={() => handleSignUp()} >Sign Up</button>}
                    </div>
                </form>
                </div>
            </div>

        </>
    )
}
