import React, {useCallback, useEffect, useState} from 'react'
import "./banner.scss"
export default function Banner({banner, handleBanner}) {
    const [closeBanner, setCloseBanner] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            handleBanner(true)
        }, 2000)
    }, [])

    const handleHideBanner = () => {
        handleBanner(false)
    }
    return (
        <>

            {banner && <div className='BannerSection'>
                <div className='Banner'>
                    <img className='BannerClose' onClick={() => handleHideBanner()} src={require("../../assets/images/closebtn.png")} alt="" />
                    <img className='BannerImg' src={require("../../assets/images/downloadapp.png")} alt="" />
                </div>
            </div>}

        </>
    )
}
