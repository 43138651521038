import { Input } from 'antd'
import React, { useState , useEffect } from 'react';
import { IoIosArrowRoundBack } from "react-icons/io"
import "./contactus.scss"
import axios from 'axios';


export default function ContactUs({ handleBackContact }) {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [validationError,setValidationError] = useState({})


    const createProduct = async (e) => {
        e.preventDefault();

        const formData = new FormData()

        formData.append('name', name)
        formData.append('email', email)
        formData.append('subject', subject)
        formData.append('message', message)

        await axios.post(`http://gameble-server.codesage.ca/api/contact-us`, formData).then(({data}) => {
            alert(data.message);

        }).catch(({response}) => {
            if (response.status === 422) {
            } else {

            }
        })
    }
    return (
        <>
            <div className='ContactUs'>
                <div className='CloseBtn' onClick={() => handleBackContact()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>

                <div className='AboutHeader'>
                    <span className='AboutTitle'>Contact us</span>
                </div>
                <form onSubmit={createProduct}>

                <div className='ContactUsForm'>
                    <div className="ContactUsBox">
                        <img className="EmailImg" src={require("../../../assets/images/user.png")} alt="" />
                        <div className='ContactUsInputBox'>
                            <span className='ContactUsTitle'>First Name</span>
                            <Input className="ContactUsInput" type="text" onChange={(event)=>{
                                setName(event.target.value)
                            }} />
                        </div>
                    </div>


                    <div className="ContactUsBox">
                        <img className="EmailImg" src={require("../../../assets/images/ic_email.png")} alt="" />
                        <div className='ContactUsInputBox'>
                            <span className='ContactUsTitle'>Email</span>
                            <Input className="ContactUsInput" type="text" onChange={(event)=>{
                                setEmail(event.target.value)
                            }} />
                        </div>
                    </div>


                    <div className="ContactUsBox">
                        <img className="EmailImg" src={require("../../../assets/images/subject.png")} alt="" />
                        <div className='ContactUsInputBox'>
                            <span className='ContactUsTitle'>Subject</span>
                            <Input className="ContactUsInput" onChange={(event)=>{
                                setSubject(event.target.value)
                            }} />
                        </div>
                    </div>

                    <div className="ContactUsMessageBox">
                        <img className="EmailImg" src={require("../../../assets/images/message.png")} alt="" />
                        <div className='ContactUsInputBox'>
                            <span className='ContactUsTitle'>Message</span>
                            <Input className="ContactUsInput" onChange={(event)=>{
                                setMessage(event.target.value)
                            }} />
                        </div>
                    </div>
                </div>

                <div className='ContactUsBtnSection'>
                    <button className='ContactUsPasswordBtn' type="submit">Submit</button>
                </div>
                </form>
            </div>
        </>
    )
}
