import React from 'react'
import { IoIosArrowForward } from "react-icons/io"
import "./setting.scss"



export default function Setting({ handleAbout, handleContact, handlePrivacy, handleTerms, handleDelete }) {
    return (
        <>
            <div className='SettingSection'>

                <div className='TitleSection'>
                    <span className='SettingTitle'>Setting</span>
                </div>

                <div className='SettingBoxContainer'>
                    <div className='SettingBox' onClick={() => handleAbout()}>
                        <span className='SettingText'>About Us</span>
                        <IoIosArrowForward className='SettingIcon' />
                    </div>

                    <div className='SettingBox' onClick={() => handleContact()}>
                        <span className='SettingText'>Contact Us</span>
                        <IoIosArrowForward className='SettingIcon' />
                    </div>

                    <div className='SettingBox' onClick={() => handlePrivacy()}>
                        <span className='SettingText'>Privacy Policy</span>
                        <IoIosArrowForward className='SettingIcon' />
                    </div>

                    <div className='SettingBox' onClick={() => handleTerms()}>
                        <span className='SettingText'>Terms & Conditions</span>
                        <IoIosArrowForward className='SettingIcon' />
                    </div>

                    <div className='SettingBox' onClick={() => handleDelete()}>
                        <span className='SettingText'>Delete Account</span>
                        <IoIosArrowForward className='SettingIcon' />
                    </div>
                </div>

            </div>
        </>
    )
}
