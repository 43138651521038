import React, { useState, useRef } from 'react';
import { IoIosArrowRoundBack } from "react-icons/io"
import "./otpauth.scss"

function OtpInputBox({ id, focusNext }) {
    const [otp, setOtp] = useState('');
    const inputRef = useRef(null);

    function handleOtpChange(event) {
        const { value } = event.target;

        if (value.length > 1) {
            setOtp('');
            return;
        }

        setOtp(value);

        if (event.key === 'Backspace' && value === '') {
            const prevInput = inputRef.current.previousSibling;
            if (prevInput) {
                prevInput.remove();
            }
        } else if (value.length === 1 && focusNext) {
            const nextInput = inputRef.current.nextSibling;
            if (nextInput) {
                nextInput.focus();
                nextInput.select();
            }
        }
    }

    return (
        <input
            type="number"
            maxLength={1}
            value={otp}
            onChange={handleOtpChange}
            className="OtpBox"
            id={id}
            ref={inputRef}
        />
    );
}




function OtpAuth({ handleOtpBack, handleResetPassword }) {
    return (

        <>
            <div className='OtpAuth'>
                <div className='CloseBtn' onClick={() => handleOtpBack()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>

                <img className='OtpImg' src={require("../../../assets/images/otp.png")} alt="" />
                <div className='OtpForm'>
                    <span className='OtpTilte'>OTP Authentication</span>
                    <span className='OtpDesc'>An authentication code has been send to
                        jackson.graham@gmail.com</span>
                </div>

                <div className='OtpBoxes'>
                    <OtpInputBox id="otp1" focusNext={true} />
                    <OtpInputBox id="otp2" focusNext={true} />
                    <OtpInputBox id="otp3" focusNext={true} />
                    <OtpInputBox id="otp4" />
                </div>

                <button className="OtpBtn" onClick={() => handleResetPassword()}>Verify</button>

                <div className='ResendOtpBox'>
                    <span className='ResendText'>Didn't receive the code?</span>
                    <span className='ResendTitle'>Resend</span>
                </div>

            </div>

        </>
    );
}

export default OtpAuth;
