import React from 'react'
import "./homeprofile.scss"

export default function HomeProfile() {
    return (
        <>
            <div className='HomeNewProfileSection'>
                <span className='HomeText'>Home</span>
            </div>
        </>
    )
}
