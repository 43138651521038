import React from 'react'
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BiSearch } from "react-icons/bi"
import "./clientapp.scss"

export default function ClientApp() {
    return (
        <>

            <div className='ClientAppSection'>
                <div className='ClientLeftBox'>
                    <span className='ClintText'>Client’s app</span>
                    <div className='ClientBtnSection'>
                        <span className='BtnWrapper'><MdOutlineKeyboardArrowLeft className='BtnIcon' /></span>
                        <span className='PageText'>Page Turner</span>
                    </div>
                </div>

                <div className='ClientRightBox'>
                    <div className='ClientSearchBox'>
                        <BiSearch className="SearchBoxIcon" />
                        <input className='SearchInput' type="text" placeholder='Search' />
                    </div>

                    <div className='ClientSearchIcons'>
                        <span className='SearchIconsWrapper'>
                            <img src={require("../../assets/images/Shape1.png")} alt="" />
                        </span>
                        <span className='SearchIconsWrapper'>
                            <img src={require("../../assets/images/maximize.png")} alt="" />
                        </span>
                        <span className='SearchIconsWrapper'>
                            <img src={require("../../assets/images/i.png")} alt="" />
                        </span>
                        <span className='SearchIconsWrapper'>
                            <img src={require("../../assets/images/Group4.png")} alt="" />
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
