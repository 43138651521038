import React, {useEffect, useState} from 'react'
import "./userProfile.scss"

export default function UserProfile({ handleShowProfileSetting }) {
    const [userName , setUserName] = useState("");

    useEffect(() => {
        if(localStorage.getItem("data") != null){
            let data = localStorage.getItem("data");
            let dataObj = JSON.parse(data);
            setUserName(dataObj.first_name + " " + dataObj.last_name);
        }
        else{
            setUserName("Jack")
        }
    } , [userName]);
    return (
        <>
            <div className='ProfileSection'>
                <div className='ProfileHeader'>
                    <span className='ProfileTitle'>
                        My Profile
                    </span>

                    <span className='ProfileEdit' onClick={() => handleShowProfileSetting()}>
                        <img src={require("../../../assets/images/edit.png")} alt="" />
                    </span>
                </div>

                <div className='ProfileAvatar'>
                    <div className='ProfileGlassAvatar'>
                        <img className='AvatarImg' src={require("../../../assets/images/Avatar.png")} alt="" />
                    </div>

                    <span className='AvatarName'>
                        { userName }
                    </span>
                </div>

                <div className='ProfileStats'>
                    <div className="ProfilStatContainer">
                        <div className='ProfileStatBox'>
                            <span className='StatTitle'>1,447</span>
                            <span className='StatDesc'>Matches</span>
                        </div>

                        <div className='ProfileStatBox'>
                            <span className='StatTitle'>$24,530</span>
                            <span className='StatDesc'>Earning</span>
                        </div>

                        <div className='ProfileStatBox'>
                            <span className='StatTitle'>597</span>
                            <span className='StatDesc'>Wins</span>
                        </div>
                    </div>
                </div>


                <div className='ProfileEarning'>
                    <div className='ProfileUpperEarning'>
                        <div className='ProfileEarningBox'>
                            <span className='EarningTitle'>$1477</span>
                            <span className='EarningDesc'>Total Earning</span>
                        </div>

                        <div className='ProfileEarningBox'>
                            <span className='EarningTitle'>$597</span>
                            <span className='EarningDesc'>Total Wager</span>
                        </div>
                    </div>


                    <div className='ProfileLowerEarning'>
                        <div className='ProfileEarningBox'>
                            <span className='EarningDesc'>Avg. Wager</span>
                            <span className='EarningTitle'>$21</span>

                        </div>

                        <div className='ProfileEarningBox'>
                            <span className='EarningDesc'>Largest Wager</span>
                            <span className='EarningTitle'>$223</span>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
