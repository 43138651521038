import React, { useState } from 'react'
import Card from '../../components/Card/Card'
import Navbar from '../../components/Navbar/Navbar'
import Playcard from '../../components/PlayCard/Playcard'
import Sidebar from '../../components/Sidebar/Sidebar'
import "./homepage.scss"
import Slider from '../../components/Slider/Slider'
import ClientApp from '../../components/ClientApp/ClientApp'
import HomeProfile from '../../components/HomeProfile/HomeProfile'
import Banner from '../../components/banner/Banner'


export default function Homepage() {

    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [showLogin, setShowLogin] = useState(true)
    const [showOtp, setShowOtp] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [showSignUp, setShowSignUp] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const [showStepOne, setShowStepOne] = useState(false)
    const [showStepTwo, setShowStepTwo] = useState(false)
    const [showStepThree, setShowStepThree] = useState(false)
    const [showStepFourth, setShowStepFourth] = useState(false)
    const [showProfileSetting, setShowProfileSetting] = useState(false)
    const [showSettingPassword, setShowSettingPassword] = useState(false)
    const [showPromo, setPromo] = useState(false)
    const [showTransaction, setShowTransaction] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [showAbout, setShowAbout] = useState(false)
    const [showContact, setShowContact] = useState(false)
    const [showPrivacy, setShowPrivacy] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showChat, setShowChat] = useState(false)

    const handleProfile = () => {
        setShowForgotPassword(false)
        setShowResetPassword(false)
        setShowOtp(false)
        setShowLogin(false)
        setShowSignUp(false)
        setShowStepOne(false)
        setShowStepOne(false)
        setShowStepThree(false)
        setShowStepFourth(false)
        setShowSettingPassword(false)
        setShowProfileSetting(false)
        setPromo(false)
        setShowTransaction(false)
        setShowSettings(false)
        setShowAbout(false)
        setShowContact(false)
        setShowPrivacy(false)
        setShowTerms(false)
        setShowDelete(false)
        setShowTransaction(false)
        setShowChat(false)
        setShowProfile(true)
    }

    const handleHome = () => {
        setShowForgotPassword(false)
        setShowResetPassword(false)
        setShowOtp(false)
        setShowSignUp(false)
        setShowStepOne(false)
        setShowStepOne(false)
        setShowStepThree(false)
        setShowStepFourth(false)
        setShowProfile(false)
        setShowProfileSetting(false)
        setShowSettingPassword(false)
        setPromo(false)
        setShowSettings(false)
        setShowAbout(false)
        setShowContact(false)
        setShowPrivacy(false)
        setShowTerms(false)
        setShowDelete(false)
        setShowTransaction(false)
        setShowChat(false)
        setShowLogin(true)
    }

    const handlePromo = () => {
        setShowForgotPassword(false)
        setShowResetPassword(false)
        setShowOtp(false)
        setShowSignUp(false)
        setShowStepOne(false)
        setShowStepOne(false)
        setShowStepThree(false)
        setShowStepFourth(false)
        setShowProfile(false)
        setShowProfileSetting(false)
        setShowSettingPassword(false)
        setShowLogin(false)
        setShowSettings(false)
        setShowAbout(false)
        setShowContact(false)
        setShowPrivacy(false)
        setShowTerms(false)
        setShowDelete(false)
        setShowTransaction(false)
        setShowChat(false)
        setPromo(true)
    }

    const handleTransaction = () => {
        setShowForgotPassword(false)
        setShowResetPassword(false)
        setShowOtp(false)
        setShowSignUp(false)
        setShowStepOne(false)
        setShowStepOne(false)
        setShowStepThree(false)
        setShowStepFourth(false)
        setShowProfile(false)
        setShowProfileSetting(false)
        setShowSettingPassword(false)
        setShowLogin(false)
        setPromo(false)
        setShowSettings(false)
        setShowAbout(false)
        setShowContact(false)
        setShowPrivacy(false)
        setShowTerms(false)
        setShowDelete(false)
        setShowChat(false)
        setShowTransaction(true)
    }

    const handleSettings = () => {
        setShowForgotPassword(false)
        setShowResetPassword(false)
        setShowOtp(false)
        setShowSignUp(false)
        setShowStepOne(false)
        setShowStepOne(false)
        setShowStepThree(false)
        setShowStepFourth(false)
        setShowProfile(false)
        setShowProfileSetting(false)
        setShowSettingPassword(false)
        setShowLogin(false)
        setPromo(false)
        setShowTransaction(false)
        setShowAbout(false)
        setShowContact(false)
        setShowPrivacy(false)
        setShowTerms(false)
        setShowDelete(false)
        setShowChat(false)
        setShowSettings(true)
    }

    const handleChat = () => {
        setShowForgotPassword(false)
        setShowResetPassword(false)
        setShowOtp(false)
        setShowSignUp(false)
        setShowStepOne(false)
        setShowStepOne(false)
        setShowStepThree(false)
        setShowStepFourth(false)
        setShowProfile(false)
        setShowProfileSetting(false)
        setShowSettingPassword(false)
        setShowLogin(false)
        setPromo(false)
        setShowTransaction(false)
        setShowAbout(false)
        setShowContact(false)
        setShowPrivacy(false)
        setShowTerms(false)
        setShowDelete(false)
        setShowSettings(false)
        setShowChat(true)
    }
    const [showBanner , setShowBanner] = useState(false);

    return (
        <>
            <Banner banner={showBanner} handleBanner={setShowBanner} />
            <Navbar />
            <div className='Homepage'>
                <Sidebar handleProfile={handleProfile} handleHome={handleHome} handlePromo={handlePromo} handleTransaction={handleTransaction} handleSettings={handleSettings} handleChat={handleChat} />

                <div className='HomePageContentSection'>
                    <HomeProfile  />

                    <div className='ComponentSection'>
                        <div className='ComponentLeftSection'>
                            <Card
                                showForgotPassword={showForgotPassword}
                                setShowForgotPassword={setShowForgotPassword}
                                showLogin={showLogin}
                                setShowLogin={setShowLogin}
                                showOtp={showOtp}
                                setShowOtp={setShowOtp}
                                showResetPassword={showResetPassword}
                                setShowResetPassword={setShowResetPassword}
                                showSignUp={showSignUp}
                                setShowSignUp={setShowSignUp}
                                showStepOne={showStepOne}
                                setShowStepOne={setShowStepOne}
                                showStepTwo={showStepTwo}
                                setShowStepTwo={setShowStepTwo}
                                showStepThree={showStepThree}
                                setShowStepThree={setShowStepThree}
                                showStepFourth={showStepFourth}
                                setShowStepFourth={setShowStepFourth}
                                showProfile={showProfile}
                                setShowProfile={setShowProfile}
                                showProfileSetting={showProfileSetting}
                                setShowProfileSetting={setShowProfileSetting}
                                showSettingPassword={showSettingPassword}
                                setShowSettingPassword={setShowSettingPassword}
                                showPromo={showPromo}
                                setShowPromo={setPromo}
                                showTransaction={showTransaction}
                                setShowTransaction={setShowTransaction}
                                showSettings={showSettings}
                                setShowSettings={setShowSettings}
                                showAbout={showAbout}
                                setShowAbout={setShowAbout}
                                showContact={showContact}
                                setShowContact={setShowContact}
                                showPrivacy={showPrivacy}
                                setShowPrivacy={setShowPrivacy}
                                showTerms={showTerms}
                                setShowTerms={setShowTerms}
                                showDelete={showDelete}
                                setShowDelete={setShowDelete}
                                showChat={showChat}
                                setShowChat={setShowChat}
                            />
                            <Playcard />
                        </div>

                        <div className="ComponentRightSection">
                            <ClientApp />
                            <Slider banner={showBanner} handleBanner={setShowBanner}  />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
