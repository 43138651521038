import React, {useCallback} from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import { Input } from 'antd'
import "./stepone.scss"

export default function StepOne({ firstName , lastName , handleNameChange , handleLastNameChange , handleStepOneBack, handleShowStepTwo }) {

    const handleFirstNameChange = useCallback(event => {
        handleNameChange(event.target.value)
    }, [handleNameChange])

    const handleLastNameChangeFun = useCallback(event => {
        handleLastNameChange(event.target.value)
    }, [handleLastNameChange])


    return (
        <>
            <div className='StepOne'>
                <div className='CloseBtn' onClick={() => handleStepOneBack()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>
                <div className='StepSections'>
                    <div className='StepLoadingBar'>
                        <div className='Loading'></div>
                    </div>
                    <div className='StepNumbers'>
                        <span className='StepTitle'>1</span>
                        <span className='StepDesc'>/4</span>
                    </div>
                </div>
                <div className='StepOneForm'>
                    <span className='StepOneTilte'>Enter Your Full Name</span>
                    <span className='StepOneDesc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                </div>

                <div className='StepOneInputBoxes'>
                    <div className="StepOneBox">
                        <img className="EmailImg" src={require("../../../assets/images/ic_email.png")} alt="" />
                        <Input className="StepOneInput" type="text" value={firstName} placeholder="First Name" onChange={handleFirstNameChange} />
                    </div>

                    <div className="StepOneBox">
                        <img className="EmailImg" src={require("../../../assets/images/ic_email.png")} alt="" />
                        <Input className="StepOneInput" type="text" value={lastName} placeholder="Last Name" onChange={handleLastNameChangeFun} />
                    </div>
                </div>

                <button className="StepOneBtn" onClick={() => handleShowStepTwo()}>Continue</button>

            </div>
        </>
    )
}
