import "./navbar.scss"
import { GoSearch } from "react-icons/go"
import { IoIosArrowBack } from "react-icons/io"
import React from 'react'
import { useNavigate } from "react-router-dom"

export default function Navbar() {
    const navigate = useNavigate()
    const destroyUserData  = () => {
        localStorage.removeItem("data")
        navigate("/welcome");
    }
    return (
        <>
            <div className="Navbar">
                <div className="NavbarLeftSection">
                    <div className="NavbarImgBox">
                        <img className="NavLogo" src={require("../../assets/images/logo.png")} alt="" />
                    </div>

                    <div className="NavbarSearchBox">
                        <GoSearch className="SearchIcon" />
                        <input type="text" className="SearchText" placeholder="Search game" />
                    </div>
                </div>

                <div className="NavbarRightSection">
                    <button className="NavbarLogoutBtn" onClick={destroyUserData}>Logout</button>
                </div>
            </div>

            <div className="NavbarCrumbMenu">
                <div className="CrumbMenuBackBox">
                    <IoIosArrowBack className="CrumbMenuIcon" />
                    <span className="CrumbMenuText">Back</span>
                </div>

                <div className="CrumbMenu">
                    <span className="CrumbMenuText">Home/Login</span>
                </div>

            </div>
        </>
    )
}
