import React, {useCallback} from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import { Checkbox, Input } from 'antd'
import "./signupstep4.scss"
import axios from "axios";

export default function StepFourth({ email , firstName , lastName , date , month , year , address , password , handlePasswordChange ,  handleStepFourthBack }) {

    const handlePasswordChangeFun = useCallback(event => {
        handlePasswordChange(event.target.value)
    }, [handlePasswordChange])

    const createUser = async (e) => {
        e.preventDefault();

        const formData = new FormData()

        formData.append('first_name', firstName)
        formData.append('last_name', lastName)
        formData.append('email', email)

        formData.append('dob', ''+ year + '-' + month + '-' + date )
        formData.append('address', address)
        formData.append('password', password)

        await axios.post(`http://gameble-server.codesage.ca/api/auth/signup`, formData).then(({data}) => {
            alert(data.message);

        }).catch(({response}) => {
            if (response.status === 422) {
            } else {

            }
        })
    }
    return (
        <>
            <div className='StepFourth'>
                <div className='CloseBtn' onClick={() => handleStepFourthBack(handleStepFourthBack)}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>
                <div className='StepSections'>
                    <div className='StepLoadingBar'>
                        <div className='Loading'></div>
                    </div>
                    <div className='StepNumbers'>
                        <span className='StepTitle'>4</span>
                        <span className='StepDesc'>/4</span>
                    </div>
                </div>
                <div className='StepFourthForm'>
                    <span className='StepFourthTilte'>Create Your Password</span>
                    <span className='StepFourthDesc'>You'll use this to log into your account.</span>
                </div>

                <div className='StepFourthInputBoxes'>
                    <div className="StepFourthBox">
                        <img className="EmailImg" src={require("../../../assets/images/Vector.png")} alt="" />
                        <Input.Password className="StepFourthInput" value={password} onChange={handlePasswordChangeFun} placeholder="Password" />
                    </div>
                </div>

                <div className='StepFourthPolicy'>
                    <Checkbox className='PolicyCheck' />
                    <span className='Policy'>I've read and agree with <span className='PolicyLink'>Terms of
                        Service</span>  and our <span className='PolicyLink'>Privacy Policy.</span></span>
                </div>

                <button className="StepFourthBtn" type="submit" onClick={createUser} >Continue</button>

            </div>
        </>
    )
}
