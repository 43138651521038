import React from 'react'
import { AiOutlineSend } from "react-icons/ai"
import "./chat.scss"

export default function Chat() {
    return (
        <>
            <div className='ChatSection'>
                <div className='ChatHeader'>
                    <img className='ChatImg' src={require("../../assets/images/logo.png")} alt="" />
                    <span className='ChatTitle'>Gameble Chat</span>
                </div>

                <div className='MessageSection'>
                    <div className='ChatTimeSection'>
                        <span className='ChatTime'>10:11 Am</span>
                    </div>

                    <div className='UserMessage'>
                        <img className='UserProfile' src={require("../../assets/images/Image.png")} alt="" />
                        <div className='UserChat'>
                            <span className='Username'>Username</span>
                            <span className='UserChat'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Adipisci laborum consequatur suscipit cumque eligendi alias quae repellendus mollitia ducimus id?</span>
                        </div>
                    </div>


                    <div className='SenderMessage'>
                        <img className='SenderProfile' src={require("../../assets/images/Image.png")} alt="" />
                        <div className='SenderChat'>
                            <span className='Sendername'>Sendername</span>
                            <span className='SenderChat'>Lorem, ipsum dolor sit amet consectetur adipisicing elit</span>
                        </div>
                    </div>

                    <div className='UserMessage'>
                        <img className='UserProfile' src={require("../../assets/images/Image.png")} alt="" />
                        <div className='UserChat'>
                            <span className='Username'>Username</span>
                            <span className='UserChat'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Adipisci laborum consequatur suscipit cumque eligendi alias quae repellendus mollitia ducimus id?</span>
                        </div>
                    </div>

                    <div className='SenderMessage'>
                        <img className='SenderProfile' src={require("../../assets/images/Image.png")} alt="" />
                        <div className='SenderChat'>
                            <span className='Sendername'>Sendername</span>
                            <span className='SenderChat'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem voluptatibus doloremque maxime nulla asperiores optio.</span>
                        </div>
                    </div>

                </div>

                <div className='SendMessageSection'>
                    <input type="text" className='TypeMessage' placeholder='Type Message' />

                    <span className='SendMessage'>
                        <AiOutlineSend className='SendMessageIcon' />
                    </span>
                </div>

            </div>
        </>
    )
}
