import React from 'react'
import { RiArrowDropRightLine } from "react-icons/ri"
import "./playcard.scss"



export default function Playcard() {
    return (
        <>
            <div className='PlayCardBox'>
                <div className='PlayCard'>
                    <span className='LetsPlayText'>Let’s Play</span>
                    <span className='ChooseCategory'>Choose a category to start playing</span>
                    <div className='LetsPlayButtonBoxes'>
                        <div className='PlayBtn'>
                            <div className='LogoWrapper'>
                                <img className='Logo' src={require("../../assets/images/Frame1.png")} alt="" />
                            </div>
                            <div className='LogoTextBox'>
                                <span className='LogoText'>Gameble</span>
                                <RiArrowDropRightLine className='LogoTextIcon' />
                            </div>
                        </div>
                        <div className='PlayBtn'>
                            <div className='LogoWrapper'>
                                <img className='Logo' src={require("../../assets/images/playstation.png")} alt="" />
                            </div>
                            <div className='LogoTextBox'>
                                <span className='LogoText'>PlayStation</span>
                                <RiArrowDropRightLine className='LogoTextIcon' />
                            </div>
                        </div>
                        <div className='PlayBtn'>
                            <div className='LogoWrapper'>
                                <img className='Logo' src={require("../../assets/images/xbox.png")} alt="" />
                            </div>
                            <div className='LogoTextBox'>
                                <span className='LogoText'>Xbox</span>
                                <RiArrowDropRightLine className='LogoTextIcon' />
                            </div>
                        </div>

                        <div className='PlayBtn'>
                            <div className='LogoWrapper'>
                                <img className='Logo' src={require("../../assets/images/xbox.png")} alt="" />
                            </div>
                            <div className='LogoTextBox'>
                                <span className='LogoText'>Nintendo</span>
                                <RiArrowDropRightLine className='LogoTextIcon' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
