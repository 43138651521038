import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./welcomepage.scss"

export default function WelcomePage() {
    const navigate = useNavigate()
    return (
        <>

            <div className='WelcomePageSection'>

                <div className='ArrowBox' onClick={() => navigate("/home")} >
                    <img className='ArrowImage' src={require("../../assets/images/arrow.png")} alt="" />
                </div>

                <div className='GambleGSection'>
                    <img className='GambleGImg' src={require("../../assets/images/Rectangle.png")} alt="" />
                </div>
                <div className='WelcomeBox'>
                    <img className='WelcomeGImg' src={require("../../assets/images/unsplashlogo.png")} alt="" />
                    <img className='HelloText' src={require("../../assets/images/Welcome.png")} alt="" />
                    <span className='StartText'>Let’s Create <br /> a Space <br /> For You.</span>
                    <div className="BtnSection">
                        <button className="SignInBtn" onClick={() => navigate("/home")} >Sign In</button>
                        <button className="SignUpBtn" onClick={() => navigate("/home")}>Sign Up</button>
                    </div>
                </div>
            </div>

        </>
    )
}
