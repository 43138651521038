import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import "./unsplash.scss"

export default function Unsplash() {
    const navigate = useNavigate()

    const Switch = () => {
        navigate("/welcome")
    }


    useEffect(() => {
        setTimeout(() => {
            Switch()
        }, 3000)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className='UnsplashSection'>
                <img src={require("../../assets/images/unsplashlogo.png")} alt="" />
            </div>
        </>
    )
}
