import React from 'react'
import "./promo.scss"

export default function Promo() {
    return (
        <>
            <div className='PromoSection'>
                <span className='PromoTitle'>Promocode</span>
                <img src={require("../../assets/images/promo.png")} alt="" />
                <span className='PromoDesc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                <div className='InviteSection'>
                    <span className='Invite'>Invite friends</span>
                    <div className='PromoBox'>
                        <span className='PromoLink'>https://lorum.ipsum.com</span>
                        <img src={require("../../assets/images/new.png")} alt="" />
                    </div>
                </div>
                <div className='PromoBtnSection'>
                    <button className='PromoBtn'>Share</button>
                </div>
            </div>
        </>
    )
}
