import React, {useState, useEffect, useCallback} from 'react';
import "./slider.scss";
import axios from 'axios';
import { useNavigate } from 'react-router-dom'


const cardsPerPage = 40;




const Slider = ({banner , handleBanner}) => {
    const handleGameClick = useCallback(event => {
        handleBanner(true)
    }, [handleBanner])

    const [fetchedData, setFetchedData] = useState([]);
    const [countTotalData, setCountTotalData] = useState([]);
    const [naxtBtn, setNextBtn] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [showClass, setShowClass] = useState('ajaxLoader');
    useEffect(() => {
        const getData = async () => {
            const datas = await axios.get(
                "http://gameble-server.codesage.ca/api/getAllGamesList?page_size=60&page="+(currentPage + 1)+""
            );
            setFetchedData(datas.data.results);
            setCountTotalData(datas.data.count);
            setNextBtn(datas.data.next);

        };
        getData();
    }, [fetchedData]);

    const [gameData , setGameData] = useState('');


    const handlePageChange = (page) => {
        setCurrentPage(page);

    };

    const renderCards = () => {
        const startIndex = currentPage * cardsPerPage;
        const endIndex = startIndex + cardsPerPage;

        return fetchedData.map((card) => (
            <div className="card" onClick={handleGameClick}>
                <img className='CardImg' src={card.background_image} alt="" />
                <span className='CardText'>{card.name}</span>
            </div>
        ));
    };

    const renderPagination = () => {
        const pageCount = Math.ceil(countTotalData / cardsPerPage);
        const pages = Array.from({ length: pageCount }, (_, i) => i);

        return (
            <div className="pagination">
                {pages.slice(0, pageCount).map((page) => (
                    <div
                        key={page}
                        style={{ borderColor: currentPage === page ? "#FFD440" : "", borderWidth: currentPage === page ? "1px" : "", borderStyle: currentPage === page ? "solid" : "", borderRadius: currentPage === page ? "50%" : "", padding: currentPage === page ? "5px" : "", transition: "all .1s" }}
                        className="PaginationBtn"
                        onClick={() => handlePageChange(page)}
                    >
                        <div className='PaginationCircle' style={{
                            backgroundColor: currentPage === page ? "#FFD440" : ""
                        }}></div>
                    </div>
                ))}
            </div>
        );
    };


    return (
        <div className="slider">
            <div className={showClass}></div>
            <div className="slider-container" >
                {renderCards()}
            </div>
            {renderPagination()}
        </div>
    );
};

export default Slider;


