import React, {useCallback, useState} from 'react'
import "./register.scss"
import { IoIosArrowRoundBack } from "react-icons/io"
import { Input } from 'antd'
import axios from "axios";


export default function Register({ email , handleEmailChange, hideSignup, handleShowStepOne }) {

    const handleInputChange = useCallback(event => {
        handleEmailChange(event.target.value)
    }, [handleEmailChange])


    return (
        <>
            <form >

            <div className='SignUpPassword'>
                <div className='CloseBtn' onClick={() => hideSignup()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>

                <img className='SignUpImg' src={require("../../../assets/images/logo.png")} alt="" />
                <div className='SignUpForm'>
                    <span className='SignUpTilte'>Getting Started</span>
                    <span className='SignUpDesc'>Please put your information below to create a new account for using app.</span>
                </div>

                <div className="SignUpBox">
                    <img className="EmailImg" src={require("../../../assets/images/ic_email.png")} alt="" />
                    <Input className="SignUpInput" type="text" placeholder="Email" value={email} onChange={handleInputChange} />
                </div>

                <button className="SignUpBtn" onClick={() => handleShowStepOne()}>Submit</button>

            </div>
            </form>
        </>
    )
}
