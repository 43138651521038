import React from 'react'
import "./sidebar.scss"

export default function Sidebar({ handleProfile, handleHome, handlePromo, handleTransaction, handleSettings, handleChat }) {
    return (
        <>
            <div className='SideBarSection'>
                <div className='Sidebar'>
                    <div className='SidebarLink' onClick={() => handleHome()}>
                        <img src={require("../../assets/images/Shape.png")} className='SideBarImg' alt="" />
                    </div>

                    <div className='SidebarLink'>
                        <img src={require("../../assets/images/wallet-passes-app.png")} className='SideBarImg' alt="" />
                    </div>

                    <div className='SidebarLink'>
                        <img src={require("../../assets/images/speedometer.png")} className='SideBarImg' alt="" />
                    </div>


                    <div className='SidebarLink' onClick={() => handleChat()} >
                        <img src={require("../../assets/images/email.png")} className='SideBarImg' alt="" />
                        <span className='MailIcon'>2</span>
                    </div>

                    <div className='SidebarLink' onClick={() => handlePromo()}>
                        <img src={require("../../assets/images/ic_code.png")} className='SideBarImg' alt="" />
                    </div>
                    <div className='SidebarLink'>
                        <img src={require("../../assets/images/calendar.png")} className='SideBarImg' alt="" />
                    </div>
                    <div className='SidebarLink' onClick={() => handleTransaction()}>
                        <img src={require("../../assets/images/spin-around.png")} className='SideBarImg' alt="" />
                    </div>

                    <div className='SidebarLink' onClick={() => handleSettings()} >
                        <img src={require("../../assets/images/Settings.png")} className='SideBarImg' alt="" />
                    </div>

                    <div className='SidebarLink' onClick={() => handleProfile()}>
                        <img src={require("../../assets/images/Frame.png")} className='SideBarImg' alt="" />
                    </div>
                </div>

                <div className='SidebarProfileSection'>
                    <div className='ProfileBox'>
                        <img className='ProfileImg' src={require("../../assets/images/Image.png")} alt="" />
                        <span className='OnlineCircle'></span>
                    </div>
                    <span className='ProfileText'>Natalie</span>
                </div>
            </div>
        </>
    )
}
