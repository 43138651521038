import { Input } from 'antd'
import React from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import "./resetpassword.scss"


export default function ResetPassword({ handleResetBack }) {
    return (
        <>

            <div className='ResetPassword'>
                <div className='CloseBtn' onClick={() => handleResetBack()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>

                <img className='ResetImg' src={require("../../../assets/images/reset.png")} alt="" />
                <div className='ResetForm'>
                    <span className='ResetTilte'>Reset Password</span>
                    <span className='ResetDesc'>Set the new password for your account so
                        you can Reset and access all the features.</span>
                </div>

                <div className='InputBox'>
                    <div className="ResetBox">
                        <img src={require("../../../assets/images/ic_lock.png")} alt="" />
                        <Input.Password className="ResetInput" placeholder="Password" />
                    </div>

                    <div className="ResetBox">
                        <img src={require("../../../assets/images/ic_lock.png")} alt="" />
                        <Input.Password className="ResetInput" placeholder="Password" />
                    </div>
                </div>

                <button className="ResetBtn">Send</button>

            </div>
        </>
    )
}
