import React, {useCallback} from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import { Input } from 'antd'
import "./signupstep2.scss"

export default function StepTwo({ date , month , year, handleDateChange , handleMonthChange , handleYearChange , handleStepTwoBack, handleStepThree }) {

    const handleDateChangeFun = useCallback(event => {
        handleDateChange(event.target.value)
    }, [handleDateChange])

    const handleMonthChangeFun = useCallback(event => {
        handleMonthChange(event.target.value)
    }, [handleMonthChange])
  const handleYearChangeFun = useCallback(event => {
        handleYearChange(event.target.value)
    }, [handleYearChange])

    return (
        <>
            <div className='StepTwo'>
                <div className='CloseBtn' onClick={() => handleStepTwoBack()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>
                <div className='StepSections'>
                    <div className='StepLoadingBar'>
                        <div className='Loading'></div>
                    </div>
                    <div className='StepNumbers'>
                        <span className='StepTitle'>2</span>
                        <span className='StepDesc'>/4</span>
                    </div>
                </div>
                <div className='StepTwoForm'>
                    <span className='StepTwoTilte'>Enter Your Date of Birth</span>
                    <span className='StepTwoDesc'>Let's check if you're old enough to play.</span>
                </div>

                <div className='StepTwoInputBoxes'>
                    <div className="StepTwoBox">
                        <img className="EmailImg" src={require("../../../assets/images/calIcon.png")} alt="" />
                        <Input placeholder='DD' value={date} onChange={handleDateChangeFun} />
                    </div>

                    <div className="StepTwoBox">
                        <img className="EmailImg" src={require("../../../assets/images/calIcon.png")} alt="" />
                        <Input placeholder='MM' value={month} onChange={handleMonthChangeFun} />
                    </div>

                    <div className="StepTwoBox">
                        <img className="EmailImg" src={require("../../../assets/images/calIcon.png")} alt="" />
                        <Input placeholder='YYYY' value={year} onChange={handleYearChangeFun}  />
                    </div>

                </div>

                <button className="StepTwoBtn" onClick={() => handleStepThree()}>Continue</button>

            </div>
        </>
    )
}
