import React from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import { Input } from 'antd'
import "./profilepassword.scss"

export default function ProfilePassword({ handleSettingPasswordBack }) {
    return (
        <>
            <div className='ProfilePasswordSection'>
                <div className='ProfileHeader'>
                    <div className='CloseBtn' onClick={() => handleSettingPasswordBack()}>
                        <IoIosArrowRoundBack className="CloseBtnIcon" />
                    </div>
                    <span className='EditText'>Edit Profile</span>
                </div>

                <div className='ProfilePasswordInputs'>
                    <div className="SettingBox">
                        <img className="EmailImg" src={require("../../../assets/images/ic_lock.png")} alt="" />
                        <div className='SettingInputBox'>
                            <Input.Password className="SettingInput" type="text" placeholder='Current Password' />
                        </div>
                    </div>

                    <div className="SettingBox">
                        <img className="EmailImg" src={require("../../../assets/images/ic_lock.png")} alt="" />
                        <div className='SettingInputBox'>
                            <Input.Password className="SettingInput" type="text" placeholder='New Password' />
                        </div>
                    </div>

                    <div className="SettingBox">
                        <img className="EmailImg" src={require("../../../assets/images/ic_lock.png")} alt="" />
                        <div className='SettingInputBox'>
                            <Input.Password className="SettingInput" type="text" placeholder='Confirm New Password' />
                        </div>
                    </div>
                </div>

                <div className='ProfileUpdateBtns'>
                    <button className='ProfileBtn'>Update</button>
                </div>
            </div>
        </>
    )
}
