import React, {useCallback} from 'react'
import { IoIosArrowRoundBack } from "react-icons/io"
import { Input } from 'antd'
import "./signupstep3.scss"

export default function StepThree({ address , handleAddressChange , handleStepFourth, handleStepThreeBack }) {

    const handleAddressChangeFun = useCallback(event => {
        handleAddressChange(event.target.value)
    }, [handleAddressChange])


    return (
        <>

            <div className='StepThree'>
                <div className='CloseBtn' onClick={() => handleStepThreeBack()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>
                <div className='StepSections'>
                    <div className='StepLoadingBar'>
                        <div className='Loading'></div>
                    </div>
                    <div className='StepNumbers'>
                        <span className='StepTitle'>3</span>
                        <span className='StepDesc'>/4</span>
                    </div>
                </div>
                <div className='StepThreeForm'>
                    <span className='StepThreeTilte'>Enter Your Address</span>
                    <span className='StepThreeDesc'>To play in contests, we're required to verify your location.</span>
                </div>

                <div className='StepThreeInputBoxes'>
                    <div className="StepThreeBox">
                        <img className="EmailImg" src={require("../../../assets/images/Vector.png")} alt="" />
                        <Input className="StepThreeInput" type="text" value={address} onChange={handleAddressChangeFun} placeholder="Address" />
                    </div>
                </div>

                <button className="StepThreeBtn" onClick={() => handleStepFourth()}>Continue</button>

            </div>
        </>
    )
}
