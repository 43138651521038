import "./card.scss"
import React, {useState} from 'react'
import Login from "../Auth/Login/Login"
import ForgotPassword from "../Auth/forgotPassword/ForgotPassword"
import OtpAuth from "../Auth/OtpAuth/OtpAuth"
import ResetPassword from "../Auth/ResetPassword/ResetPassword"
import Register from "../Auth/Register/Register"
import StepOne from '../Auth/SignupStep1/StepOne'
import StepTwo from '../Auth/SignupStep2/SignupStep2'
import StepThree from '../Auth/SignUpStep3/SignupStep3'
import StepFourth from '../Auth/SignupStep4/SignupStep4'
import UserProfile from "../Profile/UserProfile/UserProfile"
import ProfileSetting from "../Profile/ProfileSetting/ProfileSetting"
import ProfilePassword from "../Profile/ProfilePassword/ProfilePassword"
import Promo from "../Promo/Promo"
import Transaction from "../Transactions/Transaction"
import Setting from "../Settings/Setting/Setting"
import About from "../Settings/AboutUs/About"
import ContactUs from "../Settings/ContactUs/ContactUs"
import Privacy from "../Settings/PrivacyPolicy/Privacy"
import Terms from "../Settings/Terms&Conditions/Terms"
import Delete from "../Settings/DeleteAccount/Delete"
import Chat from "../Chat/Chat"






export default function Card({
    showForgotPassword,
    setShowForgotPassword,
    showLogin,
    setShowLogin,
    showOtp,
    setShowOtp,
    showResetPassword,
    setShowResetPassword,
    showSignUp,
    setShowSignUp,
    showStepOne,
    setShowStepOne,
    showStepTwo,
    setShowStepTwo,
    showStepThree,
    setShowStepThree,
    showStepFourth,
    setShowStepFourth,
    showProfile,
    setShowProfile,
    showProfileSetting,
    setShowProfileSetting,
    showSettingPassword,
    setShowSettingPassword,
    showPromo,
    showTransaction,
    showSettings,
    setShowSettings,
    showAbout,
    setShowAbout,
    showContact,
    setShowContact,
    showPrivacy,
    setShowPrivacy,
    showTerms,
    setShowTerms,
    showDelete,
    setShowDelete,
    showChat,
    setShowChat
}) {



    const handleforgotPassword = () => {
        setShowLogin(false)
        setShowForgotPassword(true)
    }

    const handleLogin = () => {
        setShowLogin(true)
        setShowForgotPassword(false)
    }

    const handleOtpAuth = () => {
        setShowForgotPassword(false)
        setShowOtp(true)
    }

    const handleOtpBack = () => {
        setShowForgotPassword(true)
        setShowOtp(false)
    }

    const handleResetPassword = () => {
        setShowOtp(false)
        setShowResetPassword(true)
    }

    const handleResetBack = () => {
        setShowResetPassword(false)
        setShowOtp(true)
    }

    const handleSignUp = () => {
        setShowLogin(false)
        setShowSignUp(true)
    }

    const hideSignup = () => {
        setShowSignUp(false)
        setShowLogin(true)
    }

    const handleShowStepOne = () => {
        setShowSignUp(false)
        setShowStepOne(true)
    }

    const handleStepOneBack = () => {
        setShowStepOne(false)
        setShowSignUp(true)
    }

    const handleShowStepTwo = () => {
        setShowStepOne(false)
        setShowStepTwo(true)
    }

    const handleStepTwoBack = () => {
        setShowStepTwo(false)
        setShowStepOne(true)
    }

    const handleStepThree = () => {
        setShowStepTwo(false)
        setShowStepThree(true)
    }

    const handleStepThreeBack = () => {
        setShowStepThree(false)
        setShowStepTwo(true)
    }

    const handleStepFourth = () => {
        setShowStepThree(false)
        setShowStepFourth(true)
    }

    const handleStepFourthBack = () => {
        setShowStepFourth(false)
        setShowStepThree(true)
    }

    const handleShowProfileSetting = () => {
        setShowProfile(false)
        setShowProfileSetting(true)
    }

    const handleBackSetting = () => {
        setShowProfileSetting(false)
        setShowProfile(true)
    }

    const handleSettingPassword = () => {
        setShowProfileSetting(false)
        setShowSettingPassword(true)

    }

    const handleSettingPasswordBack = () => {
        setShowSettingPassword(false)
        setShowProfileSetting(true)
    }

    const handleAbout = () => {
        setShowSettings(false)
        setShowAbout(true)
    }

    const handleBackAbout = () => {
        setShowAbout(false)
        setShowSettings(true)
    }
    const handleContact = () => {
        setShowSettings(false)
        setShowContact(true)
    }

    const handleBackContact = () => {
        setShowContact(false)
        setShowSettings(true)
    }

    const handlePrivacy = () => {
        setShowSettings(false)
        setShowPrivacy(true)
    }
    const handleBackPrivacy = () => {
        setShowPrivacy(false)
        setShowSettings(true)
    }

    const handleTerms = () => {
        setShowSettings(false)
        setShowTerms(true)
    }
    const handleBackTerms = () => {
        setShowTerms(false)
        setShowSettings(true)
    }
    const handleDelete = () => {
        setShowSettings(false)
        setShowDelete(true)
    }
    const handleBackDelete = () => {
        setShowDelete(false)
        setShowSettings(true)
    }
    const [email , setEmail ] = useState("");
    const [firstName , setFirstName ] = useState("");
    const [lastName , setLastName ] = useState("");
    const [date , setDate ] = useState("");
    const [month , setMonth ] = useState("");
    const [year , setYear ] = useState("");
    const [address , setAddress ] = useState("");
    const [password , setPassword ] = useState("");

    return (
        <>

            <div className="CardComponent">
                {showLogin && <Login handleforgotPassword={handleforgotPassword} handleSignUp={handleSignUp} />}
                {showForgotPassword && <ForgotPassword handleLogin={handleLogin} handleOtpAuth={handleOtpAuth} />}
                {showOtp && <OtpAuth handleOtpBack={handleOtpBack} handleResetPassword={handleResetPassword} />}
                {showResetPassword && <ResetPassword handleResetPassword={handleResetPassword} handleResetBack={handleResetBack} />}
                {showSignUp && <Register email={email} handleEmailChange={setEmail} hideSignup={hideSignup} handleShowStepOne={handleShowStepOne} />}
                {showStepOne && < StepOne firstName={firstName} lastName={lastName} handleNameChange={setFirstName}  handleLastNameChange={setLastName}  handleStepOneBack={handleStepOneBack} handleShowStepTwo={handleShowStepTwo} />}
                {showStepTwo && <StepTwo date={date} month={month} year={year} handleDateChange={setDate} handleMonthChange={setMonth} handleYearChange={setYear} handleStepTwoBack={handleStepTwoBack} handleStepThree={handleStepThree} />}
                {showStepThree && <StepThree address={address} handleAddressChange={setAddress} handleStepThreeBack={handleStepThreeBack} handleStepFourth={handleStepFourth} />}
                {showStepFourth && <StepFourth email={email} firstName={firstName} lastName={lastName} date={date} month={month} year={year} address={address}  password={password} handlePasswordChange={setPassword} handleStepFourthBack={handleStepFourthBack} />}
                {showProfile && <UserProfile handleShowProfileSetting={handleShowProfileSetting} />}
                {showProfileSetting && <ProfileSetting handleBackSetting={handleBackSetting} handleSettingPassword={handleSettingPassword} />}
                {showSettingPassword && <ProfilePassword handleSettingPassword={handleSettingPassword} handleSettingPasswordBack={handleSettingPasswordBack} />}
                {showPromo && <Promo />}
                {showTransaction && <Transaction />}
                {showSettings && <Setting handleAbout={handleAbout} handleContact={handleContact} handlePrivacy={handlePrivacy} handleTerms={handleTerms} handleDelete={handleDelete} />}
                {showAbout && <About handleBackAbout={handleBackAbout} />}
                {showContact && <ContactUs handleBackContact={handleBackContact} />}
                {showPrivacy && <Privacy handleBackPrivacy={handleBackPrivacy} />}
                {showTerms && <Terms handleBackTerms={handleBackTerms} />}
                {showDelete && <Delete handleBackDelete={handleBackDelete} />}
                {showChat && <Chat />}
            </div>

        </>
    )
}
