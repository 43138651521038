import React from 'react'
import "./forgotPassword.scss"
import { IoIosArrowRoundBack } from "react-icons/io"
import { Input } from 'antd'

export default function ForgotPassword({ handleLogin, handleOtpAuth }) {
    return (
        <>
            <div className='ForgotPassword'>
                <div className='CloseBtn' onClick={() => handleLogin()}>
                    <IoIosArrowRoundBack className="CloseBtnIcon" />
                </div>

                <img className='ForgotImg' src={require("../../../assets/images/img.png")} alt="" />
                <div className='ForgotForm'>
                    <span className='ForgotTilte'>Forgot Password</span>
                    <span className='ForgotDesc'>Enter your email for verification proccess.
                        we will send 4 digits code to your email.</span>
                </div>

                <div className="ForgotBox">
                    <img className="EmailImg" src={require("../../../assets/images/ic_email.png")} alt="" />
                    <Input className="ForgotInput" type="text" placeholder="Email" />
                </div>

                <button className="ForgotBtn" onClick={() => handleOtpAuth()}>Send</button>

            </div>
        </>
    )
}
