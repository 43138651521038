import React from 'react';
import "./transaction.scss"


export default function Transaction() {
    return (
        <>
            <div className="TransactionSection">
                <div className='TitleSection'>
                    <span className="TransactionTitle">Transaction History</span>
                </div>
                <div className="TransactionContainer">
                    <span className="TransactionHistoryTitle">Today's History</span>
                    <div className="AllTransaction">
                        <div className="TransactionBox">
                            <div className="TransactionArrow">
                                <img src={require("../../assets/images/greenarrow.png")} alt="" />
                            </div>

                            <div className="TransactionName">
                                <span className="BankName">State Bank of India</span>
                                <span className="CreditName">Credit Card</span>
                            </div>

                            <div className="TransactionPriceSection">
                                <span className="TransactionPrice">$10</span>
                            </div>
                        </div>
                        <div className="TransactionBox">
                            <div className="TransactionArrow">
                                <img src={require("../../assets/images/redarrow.png")} alt="" />
                            </div>

                            <div className="TransactionName">
                                <span className="BankName">State Bank of India</span>
                                <span className="CreditName">Credit Card</span>
                            </div>

                            <div className="TransactionPriceSection">
                                <span className="TransactionPrice">$10</span>
                            </div>
                        </div>
                        <div className="TransactionBox">
                            <div className="TransactionArrow">
                                <img src={require("../../assets/images/greenarrow.png")} alt="" />
                            </div>

                            <div className="TransactionName">
                                <span className="BankName">State Bank of India</span>
                                <span className="CreditName">Credit Card</span>
                            </div>

                            <div className="TransactionPriceSection">
                                <span className="TransactionPrice">$10</span>
                            </div>
                        </div>
                        <div className="TransactionBox">
                            <div className="TransactionArrow">
                                <img src={require("../../assets/images/redarrow.png")} alt="" />
                            </div>

                            <div className="TransactionName">
                                <span className="BankName">State Bank of India</span>
                                <span className="CreditName">Credit Card</span>
                            </div>

                            <div className="TransactionPriceSection">
                                <span className="TransactionPrice">$10</span>
                            </div>
                        </div>
                    </div>

                    <span className="TransactionHistoryTitle">18 Feb 2023</span>
                    <div className="AllTransaction">
                        <div className="TransactionBox">
                            <div className="TransactionArrow">
                                <img src={require("../../assets/images/greenarrow.png")} alt="" />
                            </div>

                            <div className="TransactionName">
                                <span className="BankName">State Bank of India</span>
                                <span className="CreditName">Credit Card</span>
                            </div>

                            <div className="TransactionPriceSection">
                                <span className="TransactionPrice">$10</span>
                            </div>
                        </div>
                        <div className="TransactionBox">
                            <div className="TransactionArrow">
                                <img src={require("../../assets/images/redarrow.png")} alt="" />
                            </div>

                            <div className="TransactionName">
                                <span className="BankName">State Bank of India</span>
                                <span className="CreditName">Credit Card</span>
                            </div>

                            <div className="TransactionPriceSection">
                                <span className="TransactionPrice">$10</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
